<template lang='pug'>
head-meta(title-page="Регистрация" )
the-registration
</template>

<script>
import TheRegistration from '../components/registration/TheRegistration'
import '@/assets/styles/components/login.sass'
import HeadMeta from '../components/_layout/HeadMeta'

export default {
  name: 'RegistrationPage',
  components: { HeadMeta, TheRegistration }
}
</script>
