<template lang='pug'>
.login
  .login__cont.cont
    template(v-if="register")
      h1.login__title.title Регистрация
      form.login__form(@submit.prevent="sendForm")
        label.login__label Имя
          input.login__input.text.input(type="text", placeholder="Имя", v-model="name", :class="{'input_wrong' : error.name}")
          span.login__error-text.text-error(v-if="error.name") {{error.name}}
        label.login__label Почта*
          input.login__input.text.input(type="email", placeholder="mail@mail.ru", v-model="email", required, :class="{'input_wrong' : error.email}")
          span.login__error-text.text-error(v-if="error.email") {{error.email}}
        label.login__label Телефон
          input.login__input.text.input(type="tel", placeholder="7 900 000 00 00",pattern="[0-9-+() ]*", v-model="phone", :class="{'input_wrong' : error.phone}")
          span.login__error-text.text-error(v-if="error.phone") {{error.phone}}
        label.login__label Пароль*
          input.login__input.text.input(type="password", placeholder="*************", v-model="password", :class="{'input_wrong' : error.password || isDifferentPasswords}")
          span.login__error-text.text-error(v-if="error.password") {{error.password}}
        label.login__label Повторите пароль*
          input.login__input.text.input(type="password", placeholder="*************", v-model="repeatPassword", :class="{'input_wrong' : isDifferentPasswords}")
          span.login__error-text.text-error(v-if="isDifferentPasswords") Пароли не совпадают
        .login__button-box
          button.login__button.button-orange.text(:disabled="disabledSubmitButton") Зарегистрироваться
        span.login__policy.text-tiny и принять условия&thinsp;
          router-link.login__policy-link(:to="{ name: 'Policy'}") Политики конфиденциальности
        ul.login__list
          li.login__item
            span.login__item-text Уже есть аккаунт?
            router-link.login__link.link_gray(:to= "{ name: 'Login' }") Войти
    template(v-else)
      h1.login__caption.caption Подтвердите почту
      p.login__text.text-big
        | Отправили ссылку с подтверждением на почту
        span.login__text-bold {{ email }}
      .login__link-box
        router-link.login__link-login.button-orange.text(:to= "{ name: 'Login' }") Войти
</template>

<script>
import '@/assets/styles/components/login.sass'
import { registration } from '../../assets/scripts/Api'

export default {
  name: 'TheRegistration',
  data () {
    return {
      register: true,
      error: { },
      email: null,
      name: null,
      phone: null,
      password: null,
      repeatPassword: null,
      isDifferentPasswords: false,
      disabledSubmitButton: false
    }
  },
  methods: {
    async sendForm (event) {
      event.preventDefault()
      if (this.password === this.repeatPassword) {
        this.isDifferentPasswords = false
      } else {
        this.isDifferentPasswords = true
        return
      }
      this.disabledSubmitButton = true
      const res = await registration(this.email, this.password, this.name, this.phone)
      window.scrollTo(0, 0)
      if (res.status === 'error') {
        this.error = res.error
      } else {
        this.error = {}
        this.register = false
      }
      this.disabledSubmitButton = false
    }
  }
}
</script>
